import { useEffect } from 'react';

interface Props {
  containerRef: React.RefObject<HTMLDivElement>;
  visible: boolean;
}

export function useFocusTrapKeyHandler({ containerRef, visible }: Props): void {
  useEffect((): (() => void) => {
    if (!visible) return () => null;

    const focusableElements: NodeListOf<HTMLElement> | undefined = containerRef?.current?.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"]',
    );

    // last focusable element may not always be visible (e.g. when it's a button in a collapsed accordion)
    // so we need to find the last visible one
    function getLastVisibleFocusableElement() {
      if (!focusableElements) return;
      for (let i = focusableElements.length - 1; i >= 0; i -= 1) {
        const element = focusableElements[i];
        if (element.offsetParent !== null) {
          return element;
        }
      }
    }

    const firstElement = focusableElements?.[0];

    const focusTrapHandler = (e: KeyboardEvent): void => {
      if (e.key === 'Tab') {
        const lastElement = getLastVisibleFocusableElement();

        // if (shift + tab) is pressed, and if the current focus is on the first element, move it to the last element
        if (e.shiftKey && document.activeElement === firstElement) {
          e.preventDefault();
          lastElement?.focus();
          // if (tab) is pressed, and if the current focus is on the last element, move it to the first element
        } else if (!e.shiftKey && document.activeElement === lastElement) {
          e.preventDefault();
          firstElement?.focus();
        }
        e.stopImmediatePropagation();
      }
    };
    document.body.addEventListener('keydown', focusTrapHandler);
    containerRef.current?.focus();

    return (): void => {
      document.body.removeEventListener('keydown', focusTrapHandler);
    };
  }, [containerRef, visible]);
}
