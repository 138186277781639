/* eslint-disable no-process-env */
import type { ConfigType } from '@newfront-insurance/shared-public-config';
import { getEnvFromUrl } from '@newfront-insurance/shared-public-config';
import developmentConfig from '@newfront-insurance/shared-public-config/development';
import productionConfig from '@newfront-insurance/shared-public-config/production';
import defaultConfig from '@newfront-insurance/shared-public-config/staging';
import merge from 'lodash/merge';

import customDevelopmentConfig from './development.json';
import customProductionConfig from './production.json';
import customDefaultConfig from './staging.json';

type CustomConfigType = typeof customDefaultConfig;

let config: ConfigType & CustomConfigType;

export function getConfig(): ConfigType & CustomConfigType {
  const env = process.env.NEWFRONT_ENV || getEnvFromUrl();

  /**
   * We handle the config this way so that Webpack will tree-shake the unused config
   * from the final build on the client.
   */
  if (env === 'prod') {
    config = { ...productionConfig, ...customProductionConfig };
  } else if (env === 'stage') {
    config = merge(defaultConfig, customDefaultConfig);
  } else {
    config = {
      ...developmentConfig,
      ...customDevelopmentConfig,
    };
  }

  return config;
}
