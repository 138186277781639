/* eslint-disable react/jsx-props-no-spreading */
import classnames from 'classnames';
import { isUndefined } from 'lodash';
import { useRef, useEffect, useState } from 'react';
import * as React from 'react';
import { useToggle } from 'react-use';
import styled, { css } from 'styled-components';

import { useDebouncedCallback } from '../../hooks/debounced-callback';
import { generateTestId } from '../../lib/test-helpers';
import { fontFamilies } from '../../theme';
import { colors } from '../../theme/colors';
import type { Size } from '../date-picker-input/types';
import { IconButton } from '../icon-button';
import { IconDate, IconSearch, IconSearch2 } from '../icons';
import { Padding } from '../padding';
import { Spinner } from '../spinner';
import { Text } from '../text';

type CallbackRef = (node: HTMLElement) => void;

export interface InputProps {
  className?: string;
  debounce?: number;
  defaultValue?: string;
  disabled?: boolean;
  withAiSuggestion?: boolean;
  fieldId?: string;
  height?: number;
  width?: string;
  innerRef?:
    | CallbackRef
    | React.RefObject<HTMLElement>
    | React.RefObject<HTMLInputElement>
    | React.RefObject<HTMLSelectElement>
    | React.RefObject<HTMLTextAreaElement>;
  invisible?: boolean;
  isInvalid?: boolean;
  maxLength?: number;
  name?: string;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => unknown;
  onKeyDown?: (e: React.KeyboardEvent) => unknown;
  onChange?: ChangeHandler;
  onFocus?: (e?: React.FocusEvent<HTMLTextAreaElement> | React.FocusEvent<HTMLInputElement>) => unknown;
  onBlur?: (value?: string) => unknown;
  onInput?: (e: React.FormEvent<HTMLInputElement>) => unknown;
  onEditableToggle?: (isDisabled: boolean) => unknown;
  placeholder?: string;
  placeholderColor?: string;
  isRequired?: boolean;
  testId?: string;
  type: InputType;
  value?: string | number | null;
  autoComplete?: 'on' | 'off' | boolean;
  appendText?: string; // eslint-disable-line react/no-unused-prop-types,
  prependText?: string;
  assistiveText?: string;
  style?: React.CSSProperties;
  size?: Size;
  ariaLabel?: string;
  autoFocus?: boolean;
}

type ChangeHandler = (value: string) => void;
type InputType =
  | 'text'
  | 'textarea'
  | 'number'
  | 'email'
  | 'tel'
  | 'select'
  | 'date'
  | 'time'
  | 'search'
  | 'search-new'
  | 'react-select'
  | 'password'
  | 'editable';

function getIcon(type: InputType): React.ReactNode {
  if (type === 'date') return <IconDate />;
  return null;
}

function InputControl(props: Omit<InputProps, 'onEditableToggle'>): JSX.Element {
  const {
    className,
    debounce = 0,
    defaultValue,
    disabled,
    fieldId,
    height,
    width,
    invisible,
    innerRef,
    isInvalid,
    maxLength,
    name,
    onClick,
    onKeyDown,
    onChange,
    onFocus,
    onBlur,
    onInput,
    placeholder,
    type,
    testId,
    isRequired,
    value,
    autoComplete = true,
    assistiveText = null,
    style,
    size,
    withAiSuggestion,
    ariaLabel = placeholder,
    autoFocus,
    placeholderColor,
  } = props;

  const debouncedOnChange = useDebouncedCallback(onChange, debounce);
  const isReadOnly = isUndefined(onChange);

  // There are some cases where we're using this component in JavaScript files
  // and null, boolean, and array values may be passed in. This won't throw an error
  // but it will render the value toString in the input, which is bad UX.
  const normalizedValue = typeof value === 'string' || typeof value === 'number' ? value : undefined;

  function handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>): void {
    const inputValue = e.target.value;

    if (debounce === 0) {
      onChange?.(inputValue);

      return;
    }

    if (debouncedOnChange) {
      debouncedOnChange(inputValue);
    }
  }

  function getTestId(): string | undefined {
    return testId ?? generateTestId(fieldId, 'input');
  }

  const classNames = classnames(
    {
      control: true,
      isInvalid,
      invisible,
    },
    className,
  );

  if (type === 'textarea') {
    return (
      <StyledTextArea
        id={fieldId}
        data-testid={getTestId()}
        name={name}
        ref={innerRef as React.RefObject<HTMLTextAreaElement>}
        className={classNames}
        placeholder={placeholder}
        disabled={disabled || isReadOnly}
        defaultValue={defaultValue}
        value={normalizedValue}
        minHeight={height}
        maxLength={maxLength}
        required={isRequired}
        onKeyDown={onKeyDown}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={(e) => {
          if (onBlur) onBlur(e.target.value);
        }}
        style={style}
        aria-label={ariaLabel}
        autoFocus={autoFocus}
      />
    );
  }

  const styledInputHeight = height || (size === 'small' ? 30 : 40);

  return (
    <>
      <StyledInputWrapper type={type} size={size}>
        <StyledInput
          id={fieldId}
          withAiSuggestion={withAiSuggestion}
          data-testid={getTestId()}
          name={name}
          ref={innerRef as React.RefObject<HTMLInputElement>}
          className={classNames}
          type={type === 'date' ? 'text' : type}
          placeholder={placeholder}
          placeholderColor={placeholderColor}
          defaultValue={defaultValue}
          disabled={disabled || isReadOnly}
          value={normalizedValue}
          readOnly={isReadOnly}
          required={isRequired}
          step="any"
          style={{
            width: width || '100%',
            height: styledInputHeight,
            ...style,
          }}
          onClick={onClick}
          onKeyDown={onKeyDown}
          onChange={handleChange}
          onFocus={onFocus}
          onBlur={(e) => {
            if (onBlur) onBlur(e.target.value);
          }}
          onInput={(e) => onInput?.(e)}
          autoComplete={autoComplete === true || autoComplete === 'on' ? 'on' : 'off'}
          onWheel={type === 'number' ? (e) => e.currentTarget.blur() : undefined} // Disables scrolling to change number input
          aria-label={ariaLabel}
          autoFocus={autoFocus}
        />
      </StyledInputWrapper>
      {assistiveText && (
        <Padding top={8} left={4}>
          <Text color={isInvalid ? colors.fire[500] : colors.steel[400]} size="small">
            {assistiveText}
          </Text>
        </Padding>
      )}
    </>
  );
}

/**
 * @deprecated Use the `Input` component from the `@newfront-insurance/core-ui/v2` package where possible.
 */
export function Input(props: InputProps): JSX.Element {
  const {
    style = {},
    type,
    appendText = null,
    prependText = null,
    size,
    disabled: inputDisabled,
    onEditableToggle,
    withAiSuggestion,
    autoFocus,
    placeholderColor,
  } = props;
  const targetRef = useRef<HTMLDivElement>(null);
  const [appendedTextWidth, setAppendedTextWidth] = useState<number | null>(null);
  const [passwordVisibilityType, setPasswordVisibilityType] = useState<InputType>(type);
  const [editableDisabled, toggleEditableDisabled] = useToggle(true);
  const disabled = type === 'editable' ? editableDisabled || inputDisabled : inputDisabled;

  useEffect(() => {
    if (targetRef.current) {
      setAppendedTextWidth(targetRef.current.getBoundingClientRect().width);
    }
  }, [appendText, prependText]);

  const icon = getIcon(type);
  const styles =
    type === 'number' && appendText ? { ...style, paddingRight: `${appendedTextWidth ?? 0 + 8}px` } : style;

  // if the type is editable or password, we need to make the padding right 40px to account for the icon
  if (type === 'editable' || type === 'password') {
    styles.paddingRight = '40px';
  }

  if (prependText) {
    styles.paddingLeft = `${appendedTextWidth ? appendedTextWidth + 8 : 0 + 8}px`;
  }

  return (
    <StyledInputWrapper type={type} size={size ?? 'regular'}>
      {/* eslint-disable-next-line */}
      {type === 'search' && (
        <div className="input-search">
          <IconSearch />
        </div>
      )}
      {type === 'search-new' && (
        <div className="input-search-new">
          <IconSearch2 color={colors.steel[400]} size={16} />
        </div>
      )}
      {prependText ? (
        <StyledPrependedText ref={targetRef}>
          <Text color="disabled">{prependText}</Text>
        </StyledPrependedText>
      ) : undefined}
      <InputControl
        {...props}
        disabled={disabled}
        type={passwordVisibilityType}
        style={styles}
        withAiSuggestion={withAiSuggestion}
        ariaLabel={props.ariaLabel ?? props.placeholder}
        autoFocus={autoFocus}
        placeholderColor={placeholderColor}
      />
      {icon && <div className="input-icon">{icon}</div>}
      {appendText ? (
        <StyledAppendedText ref={targetRef}>
          <Text color="disabled">{appendText}</Text>
        </StyledAppendedText>
      ) : null}
      {type === 'password' && (
        <EndAdornment>
          <IconButton
            type={passwordVisibilityType === 'password' ? 'showPassword' : 'hidePassword'}
            onClick={() =>
              passwordVisibilityType === 'password'
                ? setPasswordVisibilityType('text')
                : setPasswordVisibilityType('password')
            }
            ariaLabel={passwordVisibilityType === 'password' ? 'Show password' : 'Hide password'}
            title={passwordVisibilityType === 'password' ? 'Show password' : 'Hide password'}
          />
        </EndAdornment>
      )}
      {type === 'editable' && (
        <EndAdornment>
          <IconButton
            type={editableDisabled ? 'editPencil' : 'regenerate'}
            onClick={() => {
              toggleEditableDisabled();
              onEditableToggle?.(!editableDisabled);
            }}
            ariaLabel="Edit"
            title="Edit"
          />
        </EndAdornment>
      )}
    </StyledInputWrapper>
  );
}

export const LoadingTextInput = (): JSX.Element => (
  <StyledLoadingTextInput>
    <Spinner color={colors.steel[300]} />
  </StyledLoadingTextInput>
);

const StyledInput = styled.input<{ type: InputType; withAiSuggestion?: boolean; placeholderColor?: string }>`
  input[type='search']::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
  input[type='search-new']::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }

  ${({ withAiSuggestion }) =>
    withAiSuggestion &&
    `
  border-color: ${colors.purple[500]} !important;
  background: ${colors.purple[100]};
`}

  &::placeholder {
    font-weight: 300;
    color: ${({ placeholderColor }) => placeholderColor ?? colors.steel[300]};
    line-height: 21px;
  }
`;

const StyledInputWrapper = styled.div<{ type: InputType; size?: Size; placeholderColor?: string }>`
  position: relative;
  flex-grow: 1;

  .control {
    font-family: ${fontFamilies.body};
    font-size: 14px;
    border: 1px solid ${colors.steel[200]};
    line-height: 21px;
    color: ${colors.steel[500]};
    padding: 8px 12px;
    height: ${({ size }) => (size === 'small' ? '30px' : '40px')};
    box-sizing: border-box;
    width: 100%;
    display: block;
    border-radius: 4px;

    appearance: none;
    ${({ type }) =>
      type === 'search' &&
      css`
        padding-left: 32px;
      `};
    ${({ type }) =>
      type === 'search-new' &&
      css`
        padding-left: 46px;
      `};
  }
  .input-icon {
    z-index: 1;
    color: ${colors.steel[300]};
    position: absolute;
    top: ${({ size }) => (size === 'small' ? '5px' : '10px')};
    right: 10px;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
  }

  .input-search {
    z-index: 1;
    color: ${colors.steel[500]};
    position: absolute;
    top: 10px;
    left: 10px;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
  }

  .input-search-new {
    position: absolute;
    left: 20px;
    top: 14px;
    height: 16px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
  }

  .control:focus + .input-icon {
    color: ${colors.brand[300]};
  }
  .control.invisible {
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
    border: none;
    padding: 0;
    height: auto;
  }
  .control.invisible::placeholder {
    font-size: inherit;
    line-height: inherit;
  }
  .control.invisible:focus,
  .control.invisible:active:not(:disabled) {
    border: none;
    box-shadow: none;
  }
  .textarea.control {
    resize: vertical;
  }
  .control:focus {
    outline: none;
  }
  .control:disabled {
    background: ${colors.steel[100]};
  }
  .control:focus,
  .control:active:not(:disabled) {
    border-color: ${colors.brand[400]};
  }
  .control.isInvalid {
    border-color: ${colors.fire[500]};
  }
`;

const StyledLoadingTextInput = styled.div`
  background: #fafafa;
  border: 1px solid ${colors.steel[200]};
  border-radius: 3px;
  cursor: wait;
  height: 38px;
  position: relative;
`;

const StyledTextArea = styled.textarea<{ minHeight?: number }>`
  min-height: ${({ minHeight }) => `${minHeight || 40}px`};
  max-width: 100%;
  min-width: 100%;
`;

const StyledAppendedText = styled.div`
  align-items: center;
  background: #fff;
  border-left: 1px solid #dbdbdb;
  bottom: 1px;
  color: ${colors.steel[400]};
  display: flex;
  justify-content: center;
  padding: 0 8px;
  position: absolute;
  right: 1px;
  top: 1px;
  z-index: 1;
  border-start-end-radius: 4px;
  border-end-end-radius: 4px;
  &:after {
    background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.75));
    bottom: 0;
    content: '';
    left: -31px;
    position: absolute;
    top: 0;
    width: 30px;
    z-index: 0;
  }
`;

const StyledPrependedText = styled.div`
  align-items: center;
  background: ${colors.steel[100]};
  border-right: 1px solid #dbdbdb;
  bottom: 1px;
  color: ${colors.steel[400]};
  display: flex;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
  position: absolute;
  left: 1px;
  top: 1px;
  z-index: 1;
  &:after {
    background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.75));
    bottom: 0;
    content: '';
    left: -31px;
    position: absolute;
    top: 0;
    width: 30px;
    z-index: 0;
  }
`;
const EndAdornment = styled.div`
  position: absolute;
  right: 12px;
  bottom: 0;
  height: 100%;
  display: flex;
  align-items: center;
`;
