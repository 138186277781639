/* eslint react/jsx-props-no-spreading: 0 */
import * as React from 'react';

import { Chevron } from './icons';
import { generateTestId } from '../../../lib/test-helpers';
import { cn } from '../../../v2';

interface ExpandableHeadProps {
  arrowPosition?: 'right-end' | 'left' | 'right';
  children: React.ReactNode;
  // eslint-disable-next-line
  getToggleProps?: any;
  hideChevron?: boolean;
  id?: string;
  isClickAnywhere?: boolean;
  isExpanded?: boolean;
  className?: string;
  collapsedClassName?: string;
  expandedClassName?: string;
  iconClassName?: string;
  testId?: string;
  expandableBodyId?: string;
}

export const ExpandableHead = (props: ExpandableHeadProps): JSX.Element => {
  const {
    id,
    getToggleProps,
    arrowPosition = 'right-end',
    children,
    hideChevron,
    isClickAnywhere = false,
    isExpanded,
    iconClassName,
    className,
    collapsedClassName,
    expandedClassName,
    testId,
    expandableBodyId,
  } = props;
  const headToggleProps = isClickAnywhere ? getToggleProps : undefined;
  const iconToggleProps = isClickAnywhere ? undefined : getToggleProps;
  const { onClick, id: toggleId } = getToggleProps;

  return (
    <div
      role="button"
      tabIndex={0}
      className={cn(
        'relative z-0 px-6 py-6 outline-0 transition-colors duration-200 ease-in-out',
        'flex w-full cursor-default flex-row items-start',
        className,
        {
          'cursor-pointer hover:bg-steel-100 dark:hover:bg-steel-400': isClickAnywhere,
          [`expanded ${expandedClassName}`]: isExpanded,
          [`collapsed ${collapsedClassName}`]: !isExpanded,
          'justify-between': arrowPosition !== 'right',
          'flex-row-reverse': arrowPosition === 'left',
        },
      )}
      data-testid={testId}
      {...headToggleProps}
      onKeyDown={(e) => {
        if (e.key === ' ' || e.key === 'Enter') {
          e.preventDefault();
          onClick();
          e.stopPropagation();
        }
      }}
      aria-controls={expandableBodyId}
      id={id ?? toggleId}
    >
      <div className={arrowPosition === 'right' ? undefined : 'flex-1'}>{children}</div>
      {!hideChevron &&
        (!isClickAnywhere ? (
          <button
            type="button"
            className={getIconClassName({ position: arrowPosition, iconClassName, expanded: isExpanded })}
            data-testid={generateTestId(testId, 'trigger')}
            aria-label={isExpanded ? 'collapse content' : 'expand content'}
            aria-expanded={isExpanded}
            {...iconToggleProps}
          >
            <Chevron />
          </button>
        ) : (
          <span className={getIconClassName({ position: arrowPosition, iconClassName })}>
            <Chevron />
          </span>
        ))}
    </div>
  );
};

function getIconClassName({
  position,
  iconClassName,
  expanded,
}: {
  position: ExpandableHeadProps['arrowPosition'];
  iconClassName?: string;
  expanded?: boolean;
}) {
  const className = cn(
    'rotate-180 cursor-pointer outline-none transition-transform duration-300 ease-in-out',
    'flex h-8 w-8 items-center justify-center rounded-full border-0 bg-none text-steel-200 dark:text-steel-300',
    'mb-[2px] hover:bg-muted disabled:text-muted disabled:hover:bg-none',
    iconClassName,
    {
      'mr-4': position === 'left',
      'ml-4': position === 'right-end',
      'rotate-0': expanded,
    },
  );

  return className;
}
