import kebabCase from 'lodash/kebabCase';
import * as React from 'react';

import { generateTestId } from '../../lib/test-helpers';
import { cn } from '../../v2';
import { IconButton } from '../icon-button';

export interface ModalHeaderProps {
  onClose: () => unknown;
  titleText: string;
  titleTextClassName?: string;
  showBorder?: boolean;
  testId?: string;
  subtitle?: string;
  actions?: React.ReactNode[];
}

export function ModalHeader(props: ModalHeaderProps): JSX.Element {
  const {
    onClose,
    titleText,
    showBorder = true,
    testId: propTestId,
    subtitle,
    actions = [],
    titleTextClassName,
  } = props;
  const kebabCaseTitleText = kebabCase(titleText);
  const testId = propTestId ?? generateTestId(kebabCaseTitleText, 'modal-header');

  return (
    <div
      className={cn('flex justify-between p-6', {
        'border-b': showBorder,
      })}
      data-testid={testId}
    >
      <div className="flex flex-col gap-2">
        <h1 className={cn('text-lg', titleTextClassName)} data-testid={generateTestId(kebabCaseTitleText, 'title')}>
          {titleText}
        </h1>
        {subtitle ? (
          <span
            className="text-base text-muted-foreground"
            data-testid={generateTestId(kebabCaseTitleText, 'subtitle')}
          >
            {subtitle}
          </span>
        ) : null}
      </div>
      {onClose ? (
        <div className="flex justify-between gap-2">
          {actions && <div className="flex items-center gap-2">{actions}</div>}
          <IconButton
            type="close"
            onClick={onClose}
            testId={generateTestId(testId, 'close-button')}
            focusStyle={{
              border: '2px solid #245FCC',
            }}
          />
        </div>
      ) : null}
    </div>
  );
}
