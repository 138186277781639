const STAGING_API_GATEWAY_URL = 'https://stage-api-gateway.newfront-development.com';

/**
 * @type {import('./src/index.ts').ConfigType}
 */
module.exports = {
  FAVICON_ENV: 'development',
  KEYCLOAK: {
    CLIENT_ID: 'newfront-web',
    REALM: {
      CLIENTS: 'newfront-clients',
      EMPLOYEES: 'newfront',
    },
  },
  API: {
    ACCOUNTS: 'https://v2-stage-api-account.stage.newfront-development.com',
    ADDRESS: 'https://v2-stage-api-address.stage.newfront-development.com',
    AUTH: 'https://v2-stage-api-auth.newfront-development.com/auth',
    BENJI_SLACK: 'https://v2-stage-benji-slack.stage.newfront-development.com',
    BULK_IMPORT: 'https://v2-stage-api-bulk-import.stage.newfront-development.com',
    CASE_MANAGEMENT: 'https://v2-stage-api-case-mgmt.stage.newfront-development.com',
    CLAIMS: STAGING_API_GATEWAY_URL,
    CONVERSION: 'https://v2-stage-api-conversion.stage.newfront-development.com',
    COVERAGE: 'https://v2-stage-api-coverage.stage.newfront-development.com',
    DOCUMENT_EXTRACTION: 'https://v2-stage-api-doc-extr.stage.newfront-development.com',
    DOCUMENT_RENDERING: 'https://v2-stage-api-doc-rendering.stage.newfront-development.com',
    DOD: 'https://v2-stage-api-dod.stage.newfront-development.com',
    EMAIL_LOGGER: 'https://v2-stage-api-email-logger.stage.newfront-development.com',
    EXPOSURES: 'https://v2-stage-api-exposures.stage.newfront-development.com',
    FILES: 'https://v2-stage-api-files.stage.newfront-development.com',
    GRAPHQL_BFF: 'https://stage-kong.newfront-development.com/api/bff/graphql',
    NEWFRONTAPP: 'https://v2-stage-api-newfrontapp.stage.newfront-development.com',
    NOTIFICATIONS: 'https://v2-stage-api-ntfns.stage.newfront-development.com',
    PAYMENTS: 'https://v2-stage-api-payments.stage.newfront-development.com',
    PROOF_OF_INSURANCE: 'https://v2-stage-api-poi.stage.newfront-development.com',
    PROPOSAL: 'https://v2-stage-api-proposal.stage.newfront-development.com',
    QUOTING: 'https://v2-stage-api-quoting.stage.newfront-development.com',
    SEARCH: 'https://kong.stage.internal',
    TASK: 'https://v2-stage-api-task.stage.newfront-development.com',
    TOTAL_REWARDS: 'https://v2-stage-api-total-rewards.stage.newfront-development.com',
    TRADING_PARTNERS: 'https://v2-stage-api-tp.stage.newfront-development.com',
    USERS: 'https://v2-stage-api-users.stage.newfront-development.com',
  },
  SUB_APPS: {
    BULK_IMPORT: 'https://bulk-import-staging.newfront-preview.com/import',
    CERTIFICATES: 'https://proof-of-insurance-staging.newfront-preview.com/certificates',
    CLAIMS: 'https://claims-staging.newfront-preview.com/claims-app',
    CONVERSION: 'https://conversion-staging.newfront-preview.com/conversion',
    COVERAGE: 'https://coverage-staging.newfront-preview.com/admin/coverage',
    EXPOSURES: 'https://exposures-staging.newfront-preview.com/exposures',
    FILES: 'https://files-app-v2-staging.newfront-preview.com/files',
    PAYMENTS: 'https://payments-staging.newfront-preview.com/payments',
    PROPOSAL: 'https://proposals-staging.newfront-preview.com/proposal',
    QUOTING: 'https://quoting-staging.newfront-preview.com/quoting',
    TRADING_PARTNERS: 'https://trading-partners-staging.newfront-preview.com/trading-partners',
  },
  LINKS: {
    BULK_IMPORT_APP: 'https://stage-dash.newfront-development.com/import',
    CERTIFICATES_APP: 'https://stage-dash.newfront-development.com/certificates',
    CDN_BASE_URL: 'https://cdn.newfront-staging.com',
    CLAIMS_APP: 'https://stage-dash.newfront-development.com/claims-app',
    CLIENT_DASH_APP: 'https://stage-app.newfront-development.com',
    CONVERSION_APP: 'https://stage-dash.newfront-development.com/conversion',
    COVERAGE_APP: 'https://stage-dash.newfront-development.com/admin/coverage',
    DASH_APP: 'https://stage-dash.newfront-development.com',
    EXPOSURES_APP: 'https://stage-dash.newfront-development.com/exposures',
    FILES_APP: 'https://stage-dash.newfront-development.com/files',
    HEX_IP_APP: 'https://app.hex.tech/6fc62e10-586d-466c-9272-b49abe3ecaed/app/d2c717fe-f2e6-4c1d-a975-3ba27f1fcf8e',
    HEX_SOI_APP: 'https://app.hex.tech/6fc62e10-586d-466c-9272-b49abe3ecaed/app/21360a87-7963-4e00-8ade-ce0a64c25499',
    INDIO: 'https://newfront-staging.useindio.com',
    LEGACY_DASH_APP: 'https://stage-legacy.newfront-development.com',
    MARKETING_SITE: 'https://www.newfront-development.com',
    PAYMENTS_APP: 'https://stage-dash.newfront-development.com/payments',
    PROPOSAL_APP: 'https://stage-app.newfront-development.com/proposal',
    QUOTING_APP: 'https://stage-dash.newfront-development.com/quoting',
    SERVICECLOUD_URL: 'https://newfront--stage1.lightning.force.com',
    SSCPARTIAL_URL: 'https://newfront--sscpartial.sandbox.lightning.force.com/',
    TRADING_PARTNERS_APP: 'https://stage-dash.newfront-development.com/trading-partners',
  },
  LAUNCH_DARKLY: {
    PROJECTS: {
      CLIENT_DASH: '62472238978a2f1519421057',
      ADMIN_DASH: '5e5fef5aa7ff490a8b054bf8',
    },
  },
  BEAMER: {
    PRODUCT_ID: 'qRKvmOeH53231',
    CLIENT_PRODUCT_ID: 'ocuAZfEK61892',
  },
  PLAID: {
    ENVIRONMENT: 'https://development.plaid.com',
  },
  SEGMENT: {
    SOURCES: {
      CLIENT_DASH: 'DOFwjo8PqAM8ncU0z7Re7PBaJXYEErFZ',
      ADMIN_DASH: 'dUdjcFBI7gcSC1RzQTLOO09IWHUG7cWf',
    },
  },
  DATADOG: {
    ENABLE_CONSOLE_LOGGING: true,
    ENABLE_DATADOG_LOGGING: false,
    RUM: {
      ACCOUNTS: {
        APPLICATION_ID: '74076418-031d-4809-bbec-d563c4261ce8',
        CLIENT_TOKEN: 'pub1b939788303c32fe8f0a68450cc63ab9',
      },
      CLIENT_DASH: {
        APPLICATION_ID: '96e1f2df-f0b8-40ab-9d65-4b56f9cc124b',
        CLIENT_TOKEN: 'pubb25472edde35170de68b8d84f038531b',
      },
      QUOTING: {
        APPLICATION_ID: 'c491f3a2-4e90-4b66-aa08-66501573f9e5',
        CLIENT_TOKEN: 'pub02344942f3eb02bd4d44543128bbc897',
      },
      PROOF_OF_INSURANCE: {
        APPLICATION_ID: 'f6a07a45-c726-48ec-915d-543646fef866',
        CLIENT_TOKEN: 'pubef56d2cef5038808ecdb32617b22a070',
      },
      TRADING_PARTNERS: {
        APPLICATION_ID: '459cb0de-c837-4f00-977f-659524dae5da',
        CLIENT_TOKEN: 'puba445a7a99f0d5de0770f141bb08392e0',
      },
      PAYMENTS: {
        APPLICATION_ID: '90e8c0c6-6f68-4347-b22c-b2ac6fa77e74',
        CLIENT_TOKEN: 'pub105c8c35d1a34eedf1a3427886edfb05',
      },
      CLAIMS: {
        APPLICATION_ID: '00890c05-9114-4dbe-99f6-da741286944e',
        CLIENT_TOKEN: 'pubff460acf143a20a162c60a855b2b0553',
      },
      CONVERSION: {
        APPLICATION_ID: '0755d735-a909-4ac2-819b-0747023706cb',
        CLIENT_TOKEN: 'pub8b9dc2f9ef1a729184dd7795c4d75257',
      },
      BULK_IMPORT: {
        APPLICATION_ID: '30e56e4c-d41a-4169-beb8-4486106b5400',
        CLIENT_TOKEN: 'puba971403ad2035e4ec2ca7ea17660ad07',
      },
      FILES: {
        APPLICATION_ID: '6c16d41a-9cf0-442b-9a00-c0e4f8240a7d',
        CLIENT_TOKEN: 'pubd005b9ce9d43f85f1116a12d57684c8e',
      },
      PROPOSAL: {
        APPLICATION_ID: '9d534c39-43ea-4172-b0f8-931d9bd12053',
        CLIENT_TOKEN: 'pub6bf87cd9b9506422a8f7863c0a8bc871',
      },
    },
  },
  DEFAULT_USERS: {
    ACCOUNT_MANAGER: 'b9485294-6014-4395-9b76-f919e5cd54fe',
    PRODUCER: '161c83a6-b941-4064-908a-8df6aecabcda',
    BOR_ACCOUNT_MANAGER: '004b1cb1-3f7b-4832-9b8d-abe5438510f1',
  },
  REACT_QUERY: {
    ENABLE_DEVTOOLS: true,
  },
};
